exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-more-testing-tsx": () => import("./../../../src/pages/MoreTesting.tsx" /* webpackChunkName: "component---src-pages-more-testing-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-pages-testing-ground-tsx": () => import("./../../../src/pages/TestingGround.tsx" /* webpackChunkName: "component---src-pages-testing-ground-tsx" */),
  "component---src-pages-typegen-tsx": () => import("./../../../src/pages/typegen.tsx" /* webpackChunkName: "component---src-pages-typegen-tsx" */),
  "component---src-templates-blog-list-template-tsx": () => import("./../../../src/templates/blogListTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-list-template-tsx" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1023-index-md": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/Users/jahtx/Documents/_DEV_PROJECTS/GATSBY/covidnomics-g5/markdown-pages/1023/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1023-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1024-index-md": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/Users/jahtx/Documents/_DEV_PROJECTS/GATSBY/covidnomics-g5/markdown-pages/1024/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1024-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1025-index-md": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/Users/jahtx/Documents/_DEV_PROJECTS/GATSBY/covidnomics-g5/markdown-pages/1025/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1025-index-md" */),
  "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1026-index-md": () => import("./../../../src/templates/blogPostTemplate.tsx?__contentFilePath=/Users/jahtx/Documents/_DEV_PROJECTS/GATSBY/covidnomics-g5/markdown-pages/1026/index.md" /* webpackChunkName: "component---src-templates-blog-post-template-tsx-content-file-path-markdown-pages-1026-index-md" */),
  "component---src-templates-tag-list-template-tsx": () => import("./../../../src/templates/tagListTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-list-template-tsx" */)
}

